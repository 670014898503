<template>

    <div class="container">

        <div class="card card-default card-buy-now">
            <div class="card-header">
                {{$t('growing.component.buy_plant.heading')}}
            </div>
            <div class="card-body">

                <h4 class="mb-4">{{$t('growing.component.buy_plant.title_harvest')}}:</h4>

                <plant-harvest-range :pessimistic="45" :realistic="70" :optimistic="75"></plant-harvest-range>

                <accordion-card class="mt-3">
                    <template v-slot:title>
                        <i class="fad fa-hand-holding-water mx-1"></i> {{$t('growing.component.buy_plant.harvest_amount.service.title')}}
                    </template>

                    <p>
                        {{$t('growing.component.buy_plant.harvest_amount.service.description')}}
                    </p>

                    <span class="badge badge-secondary mr-1 mb-1">{{$t('growing.component.buy_plant.harvest_amount.service.list.wages')}}</span>
                    <span class="badge badge-secondary mr-1 mb-1">{{$t('growing.component.buy_plant.harvest_amount.service.list.ventilation')}}</span>
                    <span class="badge badge-secondary mr-1 mb-1">{{$t('growing.component.buy_plant.harvest_amount.service.list.rent')}}</span>
                    <span class="badge badge-secondary mr-1 mb-1">{{$t('growing.component.buy_plant.harvest_amount.service.list.lamps')}}</span>
                    <span class="badge badge-secondary mr-1 mb-1">{{$t('growing.component.buy_plant.harvest_amount.service.list.fertilizer')}}</span>
                    <span class="badge badge-secondary mr-1 mb-1">{{$t('growing.component.buy_plant.harvest_amount.service.list.goods')}}</span>
                    <span class="badge badge-secondary mr-1 mb-1">{{$t('growing.component.buy_plant.harvest_amount.service.list.rockwool')}}</span>

                </accordion-card>
                <accordion-card>
                    <template v-slot:title>
                        <i class="fad fa-boxes mx-1"></i> {{$t('growing.component.buy_plant.harvest_amount.split.title')}}
                    </template>

                    {{$t('growing.component.buy_plant.harvest_amount.split.description')}}

                </accordion-card>

                <hr>

                <h4 class="mb-4">{{$t('growing.component.buy_plant.harvest_options.title')}}:</h4>

                <accordion-card>
                    <template v-slot:title>
                        <i class="fad fa-sack-dollar mx-1"></i> {{$t('growing.component.buy_plant.harvest_options.sell.title')}}
                    </template>

                    {{$t('growing.component.buy_plant.harvest_options.sell.description')}}

                    <price-range class="my-3" :open="1"></price-range>

                    <b>{{$t('growing.component.buy_plant.harvest_options.sell.disclaimer')}}</b>

                </accordion-card>
                <accordion-card>
                    <template v-slot:title>
                        <i class="fad fa-home-lg mx-1"></i> {{$t('growing.component.buy_plant.harvest_options.delivery.title')}}
                    </template>
                    <p> {{$t('growing.component.buy_plant.harvest_options.delivery.description')}} </p>
                    <p><b>{{$t('growing.component.buy_plant.harvest_options.delivery.swiss.title')}}:</b> {{$t('growing.component.buy_plant.harvest_options.delivery.swiss.description')}} </p>
                    <p><b>{{$t('growing.component.buy_plant.harvest_options.delivery.eu.title')}}:</b> {{$t('growing.component.buy_plant.harvest_options.delivery.eu.description')}} </p>

                </accordion-card>
                <accordion-card>
                    <template v-slot:title>
                        <i class="fad fa-business-time mx-1"></i> {{$t('growing.component.buy_plant.harvest_options.business.title')}}
                    </template>

                    {{$t('growing.component.buy_plant.harvest_options.business.description')}}

                </accordion-card>

                <hr/>

              <div v-if="room && term">
                <payment-selector
                    :product="room.product"
                    :quantity-preselected="amount"
                    :quantity-limit="500"
                    :terms="term.url">

                </payment-selector>
              </div>

                <hr/>

                <p class="text-muted">
                    {{$t('growing.component.buy_plant.disclaimer')}}
                </p>
            </div>
        </div>
    </div>
</template>
<style lang="scss">

</style>
<script>
    import SaleProgress from './SaleProgress';
    import PriceRange from "./PriceRange";
    import AccordionCard from "./AccordionCard";
    import PlantHarvestRange from "./Charts/PlantHarvestRange";
    import PaymentSelector from "../../Wallet/components/PaymentSelector";

    export default {
        props: ['amount', 'room'],
        components: {
          PaymentSelector,
            PlantHarvestRange,
            AccordionCard,
            PriceRange,
            SaleProgress
        },
      data() {
        return {
          term: null
        }
      },
      beforeCreate() {
        this.$api.get('user/term/plantcontract').then(response => {
          this.term = response.data.data;
        })
      },
    }
</script>