<template>
    <div class="harvest-results w-100 my-3">
        <div class="d-flex w-100">
            <div class="pessimistic">
                <div class="text-left ml-2 pb-2">
                    {{$t('growing.component.buy_plant.expectation.pessimistic')}}
                </div>
                <div class="p-3 bg-secondary text-white text-right rounded-left">
                                    <span>
                                        {{pessimistic}}g
                                    </span>
                </div>
            </div>
            <div style="border-left: 2px solid" class="realistic">
                <div class="text-left ml-2 pb-2">
                    <b> {{$t('growing.component.buy_plant.expectation.realistic')}}</b>
                </div>
                <div class="p-3 bg-primary text-secondary text-right">
                                    <span>
                                        <b class="text-secondary">{{ realistic }}g</b>
                                    </span>
                </div>
            </div>
            <div style="border-left: 2px solid" class="optimistic">
                <div class="text-left ml-2 pb-2">
                    {{$t('growing.component.buy_plant.expectation.optimistic')}}
                </div>
                <div class="p-3 bg-gray-light text-white text-right rounded-right">
                                    <span>
                                        {{optimistic}}g
                                    </span>
                </div>
            </div>
        </div>
        <div class="text-right mt-2">
            {{$t('growing.component.buy_plant.expectation.description')}}
        </div>
    </div>
</template>
<script>
    export default {
        props: ['pessimistic', 'realistic', 'optimistic'],
    }
</script>