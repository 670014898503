<template>
    <div class="price-range">

        <div class="price-item" @click="opened=1" :class="{open: opened === 1, active: active === 1}"><span class="price"><span>0.80 EUR</span><span class="m-auto"> - </span><span>1.95 EUR</span></span><small>{{ $t('growing.component.price_range.b2b.title') }} <i class="fad fa-info-circle ml-1"></i></small>
            <p class="ml-5 pt-1 mb-0 text-secondary small description">{{ $t('growing.component.price_range.b2b.description') }}</p>
        </div>
        <div class="price-item" @click="opened=3" :class="{open: opened === 3, active: active === 3}"><span class="price"><span>2.40 EUR</span><span class="m-auto"> - </span><span>2.90 EUR</span></span><small>{{ $t('growing.component.price_range.gmp_cbd.title') }} <i class="fad fa-info-circle ml-1"></i></small>
            <p class="ml-5 pt-1 mb-0 text-secondary small description">{{ $t('growing.component.price_range.gmp_cbd.description') }}</p>
        </div>
        <div class="price-item" @click="opened=4" :class="{open: opened === 4, active: active === 4}"><span class="price"><span>2.60 EUR</span><span class="m-auto"> - </span><span>3.60 EUR</span></span><small>{{$t('growing.component.price_range.gmp_thc.title')}} <i class="fad fa-info-circle ml-1"></i></small>
            <p class="ml-5 pt-1 mb-0 text-secondary small description">{{ $t('growing.component.price_range.gmp_thc.description') }}</p>
        </div>
        <div class="price-item text-muted" @click="opened=5" :class="{open: opened === 5, active: active === 5}"><span class="price"><span>3.00 EUR</span><span class="m-auto"> - </span><span>5.00 EUR</span></span><small>{{ $t('growing.component.price_range.b2c.title') }} <i class="fad fa-info-circle ml-1"></i></small>
            <p class="ml-5 pt-1 mb-0 text-secondary small description">{{ $t('growing.component.price_range.b2c.description') }}</p>
        </div>
    </div>
</template>

<script>
    export default {
        props: {
            open: {
                type: Number,
                default: 1
            }
        },
        data() {
            return {
                active: 1,
                opened: this.open
            };
        },
        mounted() {
            this.active = this.open;
        }
    }
</script>
