<template>
  <div>
    <router-link tag="div" v-for="room in rooms" :key="room.name" class="card card-default card-plant-sale animated-fast fadeInDown" style="cursor: pointer" :to="{name: 'Growing:SaleProduct', params: {room_name: room.name}}" v-if="!roomName || roomName === room.name">
        <div class="card-body">
            <div class="card-header-large">
            <span class="icon">
                <i class="cai-location"></i>
            </span>
                <span class="info">
                  <div>
                      {{ $t('growing.component.sale_progress.pre_sale') }}:
                    {{ $t('growing.component.sale_progress.only') }} <span class="text-danger">{{room.product.sale.available}} {{ $t('growing.component.sale_progress.plants') }}</span> {{ $t('growing.component.sale_progress.available') }} |
                    <span class="text-warning">{{room.product.sale.reserved}} {{ $t('growing.component.sale_progress.plants') }}</span> {{ $t('growing.component.sale_progress.reserved') }}
                  </div>
                    Location: <span class="text-primary">{{ room.location_name }}</span> |
                    {{ $t('growing.component.sale_progress.room') }}: <span class="text-primary">{{ room.name }}</span> | {{ $t('growing.component.sale_progress.grow_start') }}: <span class="text-primary">{{ room.expected_production_start }}</span>
                </span>
                <div class="button-open"><i class="fad fa-arrow-right"></i></div>
            </div>

        </div>
    </router-link>
  </div>
</template>
<style lang="scss">

    .card-header-large {
        padding-right: 50px;
    }

    .button-open {
        position: absolute;
        right: 30px;
        top: 30px;
        font-size: 1.5em;

        transition: all .3s ease;

        :hover {
            opacity: 0.8;
        }
    }

    .open .button-open {
        transform: rotate3d(0,0,1,180deg);
    }

</style>
<script>

    export default {
      props: ['roomName'],
        components: {

        },
        data() {
            return {
                images: [
                  'mask-polen-room.png',
                  'polen-2020-aussen.png',
                  'polen-2020-fasade.png',
                  'polen-2020-flaggs.png',
                  'polen-2020-flaggs-rollups.png',
                  'polen-2020-hoodie.png',
                ],
                currentImage: 0,
                rooms: [],
                open: false
            }
        },
        beforeMount() {
            this.$api.get('growing/rooms/sale-progress').then(response => {
                this.rooms = response.data;
            });
        },
        methods: {
            nextImage() {
                this.currentImage = this.currentImage+1 < this.images.length ? this.currentImage+1 : 0;
            }
        }
    }
</script>