<template>
    <ContentWrapper>
        <breadcrumb :name="$t('growing.component.buy_plant.heading')"/>

        <div class="container">
            <buy-plant v-if="room" :room="room" :amount="amount" class="animated-fast fadeInUp"/>
        </div>

    </ContentWrapper>
</template>
<style lang="scss">

</style>
<script>
    import BuyPlant from '../components/BuyPlant';

    export default {
        components: {
            BuyPlant
        },
        data() {
            return {
              amount: 1,
              room: null
            }
        },
        mounted() {
            this.amount = this.$route.query.amount || 1;

            if (this.$route.params.room_name) {
              this.$api.get('growing/rooms/' + this.$route.params.room_name + '/sale-progress').then(response => {
                this.room = response.data;
              });
            } else {
              this.$api.get('growing/rooms/sale-progress').then(response => {
                this.$api.get('growing/rooms/' + response.data[0].name + '/sale-progress').then(response => {
                  this.room = response.data;
                });
              });
            }
        },
        methods: {}
    }
</script>
